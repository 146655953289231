import React from 'react';

function MessageRoom() {
  return (
    <div className="message-room">
      <h1>Awesome Chat Client</h1>
    </div>
  )
}

export default MessageRoom;
